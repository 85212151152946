<template>
  <v-layout wrap v-if="weekConfig">
    <v-flex xs3>
    </v-flex>
    <v-flex xs3 style="text-align: center;padding:20px 10px 0 0">
    </v-flex>
    <v-flex xs6 style="text-align: right;padding:20px 10px 0 0">
      <export-excel 
        :rows="rowConfig"
        :meals="mealConfig"
        :weeks="weekConfig"
        :contentTypeDataID="contentTypeData.ID"
      />
    </v-flex>
    <v-flex xs12 style="padding: 20px;text-align: left;">
      <table style="width:100%">
        <tr>
          <td stye="width:auto;">
      <v-btn-toggle
        v-model="selectedWeekID"
        color="primary"
      >
        <v-btn 
          :value="item.id"
          v-for="item of weekConfig"
          :key="item.id"
          @click="handleSelectWeek(item)"
        >
        <span class="hidden-sm-and-down">{{item.name}}</span>
        </v-btn>
      </v-btn-toggle>
          </td>
          <td style="width:150px;">
      <v-alert
        text
        outlined
        dense
        :color="contentTypeData.Hide ? 'deep-orange' : 'green'"
        :icon="contentTypeData.Hide ? 'mdi-eye-off' : 'mdi-eye'"
        style="max-width: 200px;"
      >
        {{ contentTypeData.Hide ? 'Oculto' : 'Activo'}}
      </v-alert>
          </td>
        </tr>
      </table>
    <!--v-btn
      v-for="item of config"
      :key="item.id"
      @click="handleSelectWeek(item)"
      style="margin: 0 10px 0 0"
      :color="selectedWeek && selectedWeek.id === item.id ? 'primary' : null"
    >
      {{item.name}}
    </v-btn-->
    </v-flex>
    <v-flex xs12 v-if="selectedWeek"
      style="padding: 0 10px;"
    >
      <weekly-table 
        :contentTypeDataID="contentTypeData.ID"
        :selectedWeek="selectedWeek"
        :meals="mealConfig"
        :rows="rowConfig"
      />
    </v-flex>
  </v-layout>
</template>
<script>
import WeeklyTable from './WeeklyTable'
import ExportExcel from './exportExcel/Index'
export default {
  components: {
    WeeklyTable,
    ExportExcel,
  },
  props: {
    contentTypeData: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    weekConfig: null,
    rowConfig: null,
    mealConfig: null,
    alt: null,
    selectedWeek: null,
    selectedWeekID: null,
  }),
  watch: {
    'contentTypeData.ID' () {
      this.prepareConfig()
    },
  },
  mounted () {
    this.prepareConfig()
  },
  methods: {
    alternation (dateStart, dateActual, alternation) {
//      if (dateStart > dateActual) return false
      const dateActualAux = dateActual.setDate(dateActual.getDate() + (-1 * parseInt((dateActual.getUTCDay() === 0 ? 7 : dateActual.getUTCDay())-1, 10)))
      const dateStartAux = dateStart.setDate(dateStart.getDate() + (-1 * parseInt((dateStart.getUTCDay() === 0 ? 7 : dateStart.getUTCDay())-1, 10)))
      if (dateStartAux > dateActualAux) return false

      const diff = parseInt(((new Date(dateActualAux) - new Date(dateStartAux)) / (1000 * 60 * 60 * 24)) / 7, 10) 
       
      if ((diff % alternation) === 0) return true
      return false
    },
    prepareConfig () {
      const aux = JSON.parse(this.contentTypeData.WeeklyMenuConfig)
      this.mealConfig = aux.meal
      this.rowConfig = aux.row
        .sort((a, b) => new Date(a.date) - new Date(b.date)) // order by date
      this.weekConfig = aux.week.map(item => {
        item.alternation = this.alternation (new Date(item.date), new Date(), aux.week.length)
        if (item.alternation) {
          item.name += ' (actual)'
          this.selectedWeek = item
          this.selectedWeekID = item.id
        }
        return item
      })
  //utils.alternation (dateStart, dateActual, alternation, isByMonth) {
    },
    handleSelectWeek (v) {
      this.selectedWeek = v
      this.selectedWeekID = v.id
    },
  },
}
</script>

