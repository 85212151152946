<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-alert
          v-bind="attrs"
          v-on="on"
          elevation="0"
          style="min-height: 50px;margin: 0;"
        >
          {{menu}}
        </v-alert>
        <!--v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
          block
        >
        {{menu}} 
        </v-btn-->
      </template>

      <v-card>
        <v-card-text style="padding-top: 20px;">
          <v-text-field 
            :id="id"
            :ref="id"
            v-model="menu" 
            label="Comida"
            outlined
            hide-details 
            @keyup.enter="handleSave"
            @keyup.esc="handleCancel"
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="handleCancel"
          >
            cancel
          </v-btn>
          <v-btn
            color="primary"
            dark
            @click="handleSave"
          >
            save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    ident: {
      type: Object,
      required: true,
    },
    editedMenu: {
      type: String,
      default: null,
    },
    onSave: {
      type: Function,
      default: null,
    },
    isActual: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
    menu: 'adfadsfasdfasdf, fasdf asdfas asdf asdf asdfas dfasdf '//'--'
  }),
  watch: {
    id () {
      this.prepareMenu()
    },
    editedMenu () {
      this.prepareMenu()
    },
    dialog (v) {
      if (v)   
        setTimeout(() => {
          this.focus()
        }, 200)
      /*
        this.$nextTick(() => {
          this.focus()
        })
        */
    },
  },
  mounted () {
    this.prepareMenu()
  },
  methods: {
    focus () {
      this.$refs[this.id].focus()//.ej2Instances.focusIn()
    },
    prepareMenu () {
      this.menu = JSON.parse(JSON.stringify(this.editedMenu ? this.editedMenu : ''))
    },
    handleCancel () {
      this.prepareMenu()
      this.dialog = false
    },
    handleSave () {
      if (this.onSave) this.onSave(this.id, this.menu, this.ident, this.isActual)
      this.dialog = false
    },
  },
}
</script>

