var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.values)?_c('div',[_c('v-btn',{staticStyle:{"margin-bottom":"10px"},attrs:{"color":"primary","dark":"","disabled":!_vm.updateActual},on:{"click":_vm.handleForceUpdate}},[_vm._v(" Actualizar XML ")]),_c('preview-view',{attrs:{"config":_vm.previewConfig,"onClose":function () { _vm.previewConfig = null }}}),_vm._l((_vm.meals),function(meal){return _c('table',{key:("meal" + (meal.id) + "week" + (_vm.selectedWeek.id)),staticStyle:{"width":"100%","margin-bottom":"20px","background-color":"#CCCCCC80","border-radius":"5px"},attrs:{"border":"0","cellspacing":"0","cellpadding":"0"}},[_c('tr',[_c('td',{staticStyle:{"text-align":"center","padding":"10px","font-size":"1.2rem"},attrs:{"colspan":"8"}},[_c('b',[_vm._v(_vm._s(meal.name))]),_vm._v(" "),(meal.icon)?_c('v-icon',[_vm._v(_vm._s(meal.icon))]):_vm._e()],1)]),_c('tr',{staticStyle:{"height":"50px"}},[_c('th',{staticStyle:{"width":"100px"}}),_vm._l((_vm.weekDay),function(day,index){return _c('th',{key:("day" + day),staticStyle:{"width":"12.5%","padding":"5px 2px","color":"black"}},[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":function($event){return _vm.handlePreview(_vm.selectedWeek.id, index, meal.id)}}},[_vm._v(" "+_vm._s(day)+" "),_c('v-icon',[_vm._v("mdi-file-find")])],1)],1)})],2),_c('tr',{staticStyle:{"background-color":"#2B5353","color":"white","height":"50px"}},[_c('th'),_vm._l((_vm.weekDay),function(day,index){return _c('th',{key:("day" + day),style:({ 
          borderTop: _vm.isActual(_vm.selectedWeek, index) ? '2px dotted red' : null,
          borderLeft: _vm.isActual(_vm.selectedWeek, index) ? '2px dotted red' : null,
          borderRight: _vm.isActual(_vm.selectedWeek, index) ? '2px dotted red' : null,
        })},[_vm._v(" "+_vm._s(day)+" ")])})],2),_vm._l((_vm.rows),function(item,index){return _c('tr',{key:index,style:({ backgroundColor: item.color ? item.color : 'black' })},[_c('td',{staticStyle:{"width":"100px","text-align":"center","color":"white"}},[_vm._v(_vm._s(item.name))]),_vm._l(([0, 1, 2, 3, 4, 5, 6]),function(i){return _c('td',{key:("meal" + meal + (item.id) + "_" + i),staticStyle:{"width":"12.5%","padding":"5px 2px","color":"white"},style:({ 
          borderBottom: _vm.isActual(_vm.selectedWeek, i) && index === _vm.rows.length -1 ? '2px dotted red' : null,
          borderLeft: _vm.isActual(_vm.selectedWeek, i) ? '2px dotted red' : null,
          borderRight: _vm.isActual(_vm.selectedWeek, i) ? '2px dotted red' : null,
        })},[(item.options && item.options.length > 0)?_c('div',_vm._l((item.options),function(option){return _c('v-card',{key:option.id,staticStyle:{"margin-bottom":"5px","background-color":"#CCCCCC80"}},[_c('v-card-text',{staticStyle:{"padding":"0"}},[_c('div',[_vm._v(_vm._s(option.name))]),_c('menu-item',{attrs:{"id":((_vm.selectedWeek.id) + "-" + (meal.id) + "-" + (item.id) + "-" + i + "-" + (option.id)),"ident":{
                    week: _vm.selectedWeek.id,
                    meal: meal.id,
                    row: item.id,
                    option: option.id,
                    day: i
                  },"editedMenu":_vm.values[((_vm.selectedWeek.id) + "-" + (meal.id) + "-" + (item.id) + "-" + i + "-" + (option.id))],"onSave":_vm.handleChangeValue,"isActual":_vm.isActual(_vm.selectedWeek, i)}})],1)],1)}),1):_c('menu-item',{attrs:{"id":((_vm.selectedWeek.id) + "-" + (meal.id) + "-" + (item.id) + "-" + i),"ident":{
            week: _vm.selectedWeek.id,
            meal: meal.id,
            row: item.id,
            day: i
          },"editedMenu":_vm.values[((_vm.selectedWeek.id) + "-" + (meal.id) + "-" + (item.id) + "-" + i)],"onSave":_vm.handleChangeValue,"isActual":_vm.isActual(_vm.selectedWeek, i)}})],1)})],2)})],2)})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }