<template>
  <div v-if="values">
      <v-btn
        color="primary"
        dark
        :disabled="!updateActual"
        @click="handleForceUpdate"
        style="margin-bottom: 10px;"
      >
        Actualizar XML
      </v-btn>
    <!--table 
      border="0" cellspacing="0" cellpadding="0"
      style="width: 100%;margin-bottom: 10px;border-radius: 5px;" 
    >
      <tr style="height: 50px;">
        <th style="width:100px;"></th>
        <th
          v-for="(day, index) of weekDay"
          :key="`day${day}`"
          style="width: 12.5%;padding: 5px 2px;color:black;" 
        >
          <v-btn color="primary" block @click="handlePreview(selectedWeek.id, index)">
          {{day}}
            <v-icon>mdi-file-find</v-icon>
          </v-btn>
        </th>
      </tr>
    </table-->
    <preview-view
      :config="previewConfig"
      :onClose="() => { previewConfig = null }"
    />
    <table 
      v-for="meal of meals"
      :key="`meal${meal.id}week${selectedWeek.id}`"
      border="0" cellspacing="0" cellpadding="0"
      style="width: 100%;margin-bottom: 20px;background-color: #CCCCCC80;border-radius: 5px;" 
    >
      <tr>
        <td colspan="8" style="text-align: center; padding: 10px;font-size:1.2rem;"><b>{{meal.name}}</b> <v-icon v-if="meal.icon">{{meal.icon}}</v-icon></td>
      </tr>
    <!-- ini meal-->
      <tr style="height: 50px;">
        <th style="width:100px;"></th>
        <th
          v-for="(day, index) of weekDay"
          :key="`day${day}`"
          style="width: 12.5%;padding: 5px 2px;color:black;" 
        >
          <v-btn color="primary" block @click="handlePreview(selectedWeek.id, index, meal.id)">
          {{day}}
            <v-icon>mdi-file-find</v-icon>
          </v-btn>
        </th>
      </tr>
    <!-- end meal-->
      <tr style="background-color: #2B5353; color: white;height: 50px;">
        <th></th>
        <th
          v-for="(day, index) of weekDay"
          :key="`day${day}`"
          :style="{ 
            borderTop: isActual(selectedWeek, index) ? '2px dotted red' : null,
            borderLeft: isActual(selectedWeek, index) ? '2px dotted red' : null,
            borderRight: isActual(selectedWeek, index) ? '2px dotted red' : null,
          }"
        >
          {{day}}
        </th>
      </tr>
      <tr
        v-for="(item, index) of rows"
        :key="index"
        :style="{ backgroundColor: item.color ? item.color : 'black' }"
      >
        <td style="width: 100px; text-align: center;color: white;">{{item.name}}</td>
        <td
          v-for="i of [0, 1, 2, 3, 4, 5, 6]"
          :key="`meal${meal}${item.id}_${i}`"
          style="width: 12.5%;padding: 5px 2px;color:white;" 
          :style="{ 
            borderBottom: isActual(selectedWeek, i) && index === rows.length -1 ? '2px dotted red' : null,
            borderLeft: isActual(selectedWeek, i) ? '2px dotted red' : null,
            borderRight: isActual(selectedWeek, i) ? '2px dotted red' : null,
          }"
        >
          <div 
            v-if="item.options && item.options.length > 0"
          >
          <v-card
            v-for="option of item.options"
            :key="option.id"
            style="margin-bottom: 5px; background-color:#CCCCCC80;"
          >
            <v-card-text style="padding: 0;">
              <div>{{option.name}}</div>
                  <menu-item 
                    :id="`${selectedWeek.id}-${meal.id}-${item.id}-${i}-${option.id}`"
                    :ident="{
                      week: selectedWeek.id,
                      meal: meal.id,
                      row: item.id,
                      option: option.id,
                      day: i
                    }"
                    :editedMenu="values[`${selectedWeek.id}-${meal.id}-${item.id}-${i}-${option.id}`]"
                    :onSave="handleChangeValue"
                    :isActual="isActual(selectedWeek, i)"
                  />
            </v-card-text>
          </v-card>
        </div>
          <menu-item 
            v-else
            :id="`${selectedWeek.id}-${meal.id}-${item.id}-${i}`"
            :ident="{
              week: selectedWeek.id,
              meal: meal.id,
              row: item.id,
              day: i
            }"
            :editedMenu="values[`${selectedWeek.id}-${meal.id}-${item.id}-${i}`]"
            :onSave="handleChangeValue"
            :isActual="isActual(selectedWeek, i)"
          />
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import api from '@/services/api'
import MenuItem from './MenuItem'
import PreviewView from './Preview'
export default {
  name: 'WeeklyTable',
  components: {
    MenuItem,
    PreviewView,
  },
  props: {
    contentTypeDataID: {
      type: String,
      required: true,
    },
    selectedWeek: {
      type: Object,
      required: true,
    },
    meals: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    values: null,
    updateActual: false,
    previewConfig: null,
  }),
  computed: {
    weekDay () {
      return [
        'Lunes',
        'Martes',
        'Mi�rcoles',
        'Jueves',
        'Viernes',
        'S�bado',
        'Domingo',
      ]
    },
    isDev () {
      return process.env.VUE_APP_ENV === 'development'
    },
  },
  watch: {
    'selectedWeek.id' () {
      //this.prepareMenus()
      this.handleGetData()
    },
  },
  mounted () {
    //this.prepareMenus()
    this.handleGetData()
  },
  methods: {
    isActual (v, vDay) {
      if (!v.alternation) return false
      let day = new Date().getDay()
      day = day === 0 ? 6 : day - 1
      return day === vDay
    },
    prepareMenus (v) {
      this.values = {}
      for (const meal of this.meals)
        for (const row of this.rows) {
          for (let i=0; i < 7; i++) {
            if (row.options && row.options.length > 0) {
              for (const option of row.options) {
                const id = `${this.selectedWeek.id}-${meal.id}-${row.id}-${i}-${option.id}`
                //const aux = v.filter(x => x.ID === id).map(item => item.Value).shift()
                const aux = v.filter(x => 
                  x.Week === this.selectedWeek.id &&
                  x.Row === row.id &&
                  x.Meal === meal.id &&
                  x.Day === i.toString() &&
                  x.Option === option.id
                ).map(item => item.Value).shift()
                this.values[id] = aux
              }
            } else {
              const id = `${this.selectedWeek.id}-${meal.id}-${row.id}-${i}`
              const aux = v.filter(x => 
                x.Week === this.selectedWeek.id &&
                x.Row === row.id &&
                x.Meal === meal.id &&
                x.Day === i.toString() 
              ).map(item => item.Value).shift()
              this.values[id] = aux
            }
          }
        }
    },
    handleForceUpdate () {
      api.getAllWithoutLimit ('dviewcontent', `v1/private/weekly-menu/${this.contentTypeDataID}/generate-media`)
        .then((response) => {   
          if (response) this.updateActual = false
        })

    },
    handleGetData () {
      api.getAllWithoutLimit ('dviewcontent', `v1/private/weekly-menu/${this.contentTypeDataID}`)
        .then((response) => {   
          this.prepareMenus(response)
        })

    },
    handleChangeValue (id, v, ident, isActual) {
      const body = {
        id,
        ident,
        value: v,
        isActual
      }
      api.addItem ('dviewcontent', `v1/private/weekly-menu/${this.contentTypeDataID}`, body)
        .then(() => {   
          if (isActual) this.updateActual = true
          this.values[id] = v
        })
    },
    handlePreview (week, day, meal) {
      const url = this.isDev ? `http://localhost:8081/weeklyMenu/index.html?id=${this.contentTypeDataID}&week=${week}&day=${day}&meal=${meal}` :
        `https://widgets.dviewcontent.com/weeklyMenu/index.html?id=${this.contentTypeDataID}&week=${week}&day=${day}&meal=${meal}`

      this.previewConfig = {
        url
      }
      //var win = window.open(url, '_blank')
      //win.focus()
    },
  },
}
</script>

