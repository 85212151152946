<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
          elevation="0"
          style="border-radius:0"
        >
          <v-btn
            icon
            dark
            @click="onClose"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="onClose"
            >
              {{ $t('common.close', locale)}}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row no-gutters>
          <v-col
            cols="12"
            xs="12"
            style="padding-top: 20px;"
          >
            <center>
              <div
                v-if="size && content"
                elevation="2"
                class="main-template-preview" 
                :style="{ width: `${size.width}px`, height: `${size.height}px` }"
              >
                <div
                  v-html="content.content"
                />
              </div>
            </center>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  components: {
  },
  props: {
    config: {
      type: Object,
      default: null
    },
    locale: {
      type: String,
      default: 'es'
    },
    prevHeight: {
      type: Number,
      default: 1080,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    size: null,
    content: null,
  }),
  computed: {
    originalWidth () {
      return '1920px'
    },
    originalHeight () {
      return '1080px'
    },
    scaleFactor () {
      return 0.417
    }
  },
  watch: {
    'config.url' (v) {
      if (!v) {
        this.dialog = false
        return
      }
      this.handlePrepare()
      this.dialog = true
    },
    dialog () {
      this.handlePrepare()
    },
  },
  mounted () {
    this.size = this.scaleSize({width: 1920, height: 1080})
    this.handlePrepare()
  },
  methods: {
    prepareMediaContent (url) {
      const content = `<iframe class="frame-weekly" src="${url}" style="border:0;width:${this.originalWidth};height:${this.originalHeight};-ms-zoom: ${this.scaleFactor};-moz-transform: scale(${this.scaleFactor});-moz-transform-origin: 0 0;-o-transform: scale(${this.scaleFactor});-o-transform-origin: 0 0;-webkit-transform: scale(${this.scaleFactor});-webkit-transform-origin: 0 0;" />`

      return {
        content,
      }
    },

    async handlePrepare () {
      if (!this.config || !this.config.url) return
      
      this.content = this.prepareMediaContent(this.config.url)
    },
    scaleSize (v, maxH) {
      const maxHeight = maxH ? maxH : 450
      if (v.height > maxHeight) {
        v.width = v.width * maxHeight / v.height
        v.height = maxHeight
      }
      return v
    },
  },
}
</script>

<style>
.frame-weekly {
  border-radius: 20px;
  box-shadow: 4px 6px 15px -7px rgba(0,0,0,0.61);
  -webkit-box-shadow: 4px 6px 15px -7px rgba(0,0,0,0.61);
  -moz-box-shadow: 4px 6px 15px -7px rgba(0,0,0,0.61);
}
</style>



